import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const buildVersion = process.env.REACT_APP_BUILD_VERSION || "dev"; // магія ?

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `/locales/{{lng}}.json?v=${buildVersion}`,
    },
    fallbackLng: "en",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
