import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VanillaTilt from "vanilla-tilt";

import { ProjectCard } from "./card";

import foxImg from "../../../assets/foxminded.png";
import FlagLineIng from "../../../assets/img/flag-line.png";

import MokeupGif from "../../../assets/gif-tabs/mokeup.gif";
import SunsetGif from "../../../assets/gif-tabs/sunset.gif";
import CSSDocsGif from "../../../assets/gif-tabs/CSSDocs.gif";
import FlagsGif from "../../../assets/gif-tabs/Flags.gif";
import nextFoodGif from "../../../assets/gif-tabs/nextFood.gif";
import RestaurantGif from "../../../assets/gif-tabs/RestaurantMenu.gif";
import SliderPexelsGif from "../../../assets/gif-tabs/sliderPexels.gif";
import WeatherWidgetGif from "../../../assets/gif-tabs/weatherWidget.gif";
import MarvelGif from "../../../assets/gif-tabs/Marvel.gif";
import InacademyGif from "../../../assets/gif-tabs/inacademy.gif";

import MokeupImg from "../../../assets/img-tabs/mokeup.png";
import SunsetImg from "../../../assets/img-tabs/sunset.png";
import CSSDocsImg from "../../../assets/img-tabs/CSSDocs.png";
import FlagsImg from "../../../assets/img-tabs/Flags.png";
import nextFoodImg from "../../../assets/img-tabs/nextFood.png";
import RestaurantImg from "../../../assets/img-tabs/RestaurantMenu.png";
import SliderPexelsImg from "../../../assets/img-tabs/sliderPexels.png";
import WeatherWidgetImg from "../../../assets/img-tabs/weatherWidget.png";
import MarvelImg from "../../../assets/img-tabs/Marvel.png";
import InacademyImg from "../../../assets/img-tabs/inacademy.png";

import "./tabsStyle.scss";

export const TabsGrid = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  return (
    <main className="tabGrid">
      <img className="tabGrid__flagLine" src={FlagLineIng} alt="FlagLine" />
      <nav className="tabGrid__nav">
        <button
          className={`tabGrid__tab ${
            activeTab === 1 ? "tabGrid__tab--active" : ""
          }`}
          onClick={() => setActiveTab(1)}
        >
          {t("titles.student_projects")}
        </button>

        <button
          className={`tabGrid__tab ${
            activeTab === 2 ? "tabGrid__tab--active" : ""
          }`}
          onClick={() => setActiveTab(2)}
        >
          {t("titles.business_projects")}
        </button>

        <button
          className={`tabGrid__tab ${
            activeTab === 3 ? "tabGrid__tab--active" : ""
          }`}
          onClick={() => setActiveTab(3)}
        >
          {t("titles.my_certificates")}
        </button>
      </nav>

      <section className="tabGrid__layout">
        {activeTab === 1 && <StudentTab t={t} />}
        {activeTab === 2 && <BusinessTab t={t} />}
        {activeTab === 3 && <CerteficateTab />}
      </section>
    </main>
  );
};

export const StudentTab = ({ t }) => {
  const projects = [
    {
      title: t("projects.nexlevel_food"),
      gifSrc: nextFoodGif,
      imgSrc: nextFoodImg,
      url: "https://food-next-js-nu.vercel.app/",
      url2: "https://github.com/akoval29/Food_NextJS",
      tech: ["next-js", "react", "javaScript", "html", "css"],
      description: t("descr.tasty_food_recipes"),
    },
    {
      title: t("projects.sunset"),
      gifSrc: SunsetGif,
      imgSrc: SunsetImg,
      url: "https://sunset-d77d3.web.app/",
      url2: "https://github.com/akoval29/Sunset",
      tech: ["react", "redux", "javaScript", "html", "css"],
      description: t("descr.react_redux_app"),
    },
    {
      title: t("projects.slider"),
      gifSrc: SliderPexelsGif,
      imgSrc: SliderPexelsImg,
      url: "https://slider-f00ff.web.app/",
      url2: "https://github.com/akoval29/Slider",
      tech: ["react", "javaScript", "html", "css"],
      description: t("descr.pexels_slider"),
    },
    {
      title: t("projects.marvel_heroes"),
      gifSrc: MarvelGif,
      imgSrc: MarvelImg,
      url: "https://marvel-3d657.web.app/",
      url2: "https://github.com/akoval29/Marvel",
      tech: ["react", "javaScript", "html", "css"],
      description: t("descr.marvel_heroes_app"),
    },
    {
      title: t("projects.where_in_the_world"),
      gifSrc: FlagsGif,
      imgSrc: FlagsImg,
      url: "https://flags-mauve.vercel.app/",
      url2: "https://github.com/akoval29/Flags",
      tech: ["javaScript", "html", "css"],
      description: t("descr.country_info_app"),
    },
    {
      title: t("projects.weather_widget_name"),
      gifSrc: WeatherWidgetGif,
      imgSrc: WeatherWidgetImg,
      url: "https://weather-widget-tau.vercel.app/",
      url2: "https://github.com/akoval29/WeatherWidget",
      tech: ["javaScript", "html", "css"],
      description: t("descr.weather_widget"),
    },
    {
      title: t("projects.mockup"),
      gifSrc: MokeupGif,
      imgSrc: MokeupImg,
      url: "https://akoval29.github.io/Mockup/",
      url2: "https://github.com/akoval29/Mockup",
      tech: ["html", "css"],
      description: t("descr.adaptive_responsive_app"),
    },
    {
      title: t("projects.restaurant_menu"),
      gifSrc: RestaurantGif,
      imgSrc: RestaurantImg,
      url: "https://akoval29.github.io/RestaurantMenu/",
      url2: "https://github.com/akoval29/RestaurantMenu",
      tech: ["html", "css"],
      description: t("descr.restaurant_menu_app"),
    },
    {
      title: t("projects.css_documentation"),
      gifSrc: CSSDocsGif,
      imgSrc: CSSDocsImg,
      url: "https://akoval29.github.io/CSS-Documentation/",
      url2: "https://github.com/akoval29/CSS-Documentation",
      tech: ["html", "css"],
      description: t("descr.css_guide_website"),
    },
  ];

  return (
    <div className="projectGrid">
      {projects.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}
    </div>
  );
};

export const BusinessTab = ({ t }) => {
  const projects = [
    {
      title: "inacademy MBO",
      gifSrc: InacademyGif,
      imgSrc: InacademyImg,
      url: "https://www.inacademy.nl/",
      url2: null,
      tech: ["react", "javaScript", "html", "css"],
      description: t("descr.dutch_language_courses"),
    },
  ];

  return (
    <div className="projectGrid">
      {projects.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}
    </div>
  );
};

const CerteficateTab = () => {
  const { t } = useTranslation();

  const downloadImage = (imgSrc) => {
    const confirmed = window.confirm("Завантажити зображення?");
    if (!confirmed) return;

    const link = document.createElement("a");
    link.href = imgSrc;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const images = document.querySelectorAll(".tilt-img");
    VanillaTilt.init(images, {
      max: 25,
      speed: 200,
      glare: true,
      "max-glare": 0.5,
    });
  }, []);

  return (
    <img
      title={t("card.click_to_download")}
      className="tabGrid__img tilt-img"
      src={foxImg}
      alt="Foxminded certificate"
      onClick={() => downloadImage(foxImg)}
    />
  );
};
