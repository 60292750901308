import React from "react";
import peaksGif from "../../assets/gif/peaks.gif";
import { scrollToElement } from "../../utils/scroll";
import { useHttp } from "./useHttp";

export const Playlist = ({
  selectVideo,
  setVideos,
  currentVideoId,
  isPlaying,
  t,
}) => {
  const videosList = useHttp(setVideos);

  const handleMouseEnter = () => {
    if (currentVideoId && videosList) {
      const currentVideoIndex = videosList.findIndex(
        (video) => video.snippet.resourceId.videoId === currentVideoId
      );
      if (currentVideoIndex !== -1) {
        scrollToElement(currentVideoIndex);
      }
    }
  };

  return (
    <main className="playlist" onMouseEnter={handleMouseEnter}>
      <h2 className="playlist__title">{t("music.playlist")}</h2>
      <ul className="playlist__ul">
        {videosList.map((video, index) => {
          const isCurrent = video.snippet.resourceId.videoId === currentVideoId;

          return (
            <li
              className={`playlist__li ${
                isCurrent ? "playlist__li--active" : ""
              }`}
              key={video.id}
              onClick={() => {
                selectVideo(video.snippet.resourceId.videoId, index);
              }}
            >
              <img
                className="playlist__img"
                src={video.snippet.thumbnails.default.url}
                alt="songImg"
              />
              <div className="playlist__col">
                <p className="playlist__author">
                  {video.snippet.videoOwnerChannelTitle.replace(" - Topic", "")}
                </p>
                <p className="playlist__song">{video.snippet.title}</p>
              </div>

              <img
                className={`playlist__gif ${
                  isCurrent && isPlaying && "active"
                }`}
                src={peaksGif}
                alt="peaks gif"
              />
            </li>
          );
        })}
      </ul>
    </main>
  );
};
