import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { useModal } from "../../Modals/ModalProvider";

import Spinner from "../spinner";
import defAva from "../../assets/img-logo/noname-ava2.png";
import loginImg from "../../assets/img-logo/login.png";

export const LoginButton = ({
  showAvatarMenu,
  setShowAvatarMenu,
  headerResize,
  mod,
}) => {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);

  const { openModal } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(t("toasts.error_occurred"));
    }
  }, [error, t]);

  const handleMenubtnClick = () => {
    setShowAvatarMenu(false);
    navigate("/cabinet");
  };
  const handleMouseEnter = () => {
    setShowAvatarMenu(true);
  };
  const handleMouseLeave = () => {
    setShowAvatarMenu(false);
  };
  const handleLogout = () => {
    auth.signOut();
    setShowAvatarMenu(false);
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Визначаємо статус автентифікації
  let authStatus;
  if (loading) {
    authStatus = "loading";
  } else if (user) {
    authStatus = "authenticated";
  } else {
    authStatus = "unauthenticated";
  }

  return (
    <section
      className="header__loginBtn"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setShowAvatarMenu(!showAvatarMenu)}
    >
      {(() => {
        switch (authStatus) {
          case "loading":
            return <Spinner />;
          case "authenticated":
            return (
              <img
                className="header__logo"
                src={user.photoURL || defAva}
                alt="Avatar"
              />
            );
          case "unauthenticated":
          default:
            return (
              <img
                className="header__logo header__logo--transparent"
                onClick={() => openModal("login")}
                src={loginImg}
                alt="MBO-login"
                id="login-btn"
              />
            );
        }
      })()}
    </section>
  );
};
