import React from "react";
import { useTranslation } from "react-i18next";
import "./footerStyle.scss";

export const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__col">
          <h3 className="footer__title">{t("header.company_name")}</h3>
          <p className="footer__text">{t("header.address_current")}</p>
          <p className="footer__text">
            {t("header.tax_identification_number")}
          </p>
        </div>

        <div className="footer__col">
          <h3 className="footer__title">{t("titles.contacts")}</h3>
          <p className="footer__text">
            <a href="tel:+380977599895" className="footer__link">
              {t("header.phone")}: +38(097)759-98-95
            </a>
          </p>
          <p className="footer__text">
            <a href="mailto:akoval29@gmail.com" className="footer__link">
              Email: akoval29@gmail.com
            </a>
          </p>
        </div>

        <div className="footer__col">
          <h3 className="footer__title">{t("header.social_media")}</h3>
          <div className="footer__socials">
            <a
              href="viber://chat?number=%2B380977599895"
              className="footer__social-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733585.png"
                alt="Viber"
              />
            </a>
            <a href="https://t.me/akoval29" className="footer__social-link">
              <img
                src="https://cdn-icons-png.flaticon.com/512/2111/2111646.png"
                alt="Telegram"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/akoval29/"
              className="footer__social-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/145/145807.png"
                alt="linkedIn"
              />
            </a>
            <a
              href="https://github.com/akoval29"
              className="footer__social-link"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/733/733609.png"
                alt="GitHub"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="footer__divider"></div>

      <div className="footer__bottom">
        <p className="footer__copy">
          © {currentYear}. {t("header.company_type")} {t("header.company_name")}
          . {t("header.all_rights_reserved")}
        </p>
      </div>
    </footer>
  );
};
