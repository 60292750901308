// services/authService.js
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, getDocs, query, where, addDoc } from "firebase/firestore";

export async function handleAuth(email, password, auth, firestore) {
  const usersRef = collection(firestore, "users");
  const q = query(usersRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // 🔐 User exists → log in
    await signInWithEmailAndPassword(auth, email, password);
    return "login";
  } else {
    // 🆕 Register and save in Firestore
    await createUserWithEmailAndPassword(auth, email, password);
    await addDoc(usersRef, { email, createdAt: Date.now() });
    return "register";
  }
}
