import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import { handleAuth } from "../../utils/userActions";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { useModal } from "../../Modals/ModalProvider";
import { Context } from "../../index";

import openEye from "../../assets/img/open2.png";
import closeEye from "../../assets/img/close2.png";

import "../modalStyle.scss";

export const LoginModal = () => {
  const { closeModal, modal } = useModal();
  const showLoginModal = modal.isOpen && modal.type === "login";

  const { t } = useTranslation();
  const { auth, firestore } = useContext(Context);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputType, setInputType] = useState("password");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const storedEmail = Cookies.get("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleCloseModal = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      closeModal();
    }
  };

  const togglePasswordVisibility = () => {
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  };

  const handlePassRecover = () => {
    navigate("/password-recovery");
    closeModal();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await handleAuth(email, password, auth, firestore);

      if (result === "login") {
        toast.success(t("toasts.you_logged_in"));
      } else {
        toast.success(t("toasts.registration_successful"));
      }

      Cookies.set("email", email, { expires: 7 });
      closeModal();
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          toast.error(t("toasts.invalid_email"));
          break;
        case "auth/weak-password":
          toast.error(t("toasts.error_occurred")); // no specific key
          break;
        case "auth/email-already-in-use":
          toast.error(t("toasts.already_registered"));
          break;
        case "auth/wrong-password":
          toast.error(t("toasts.wrong_password"));
          break;
        case "auth/too-many-requests":
          toast.error(t("toasts.too_many_requests"));
          break;
        default:
          toast.error(t("toasts.error_occurred"));
          console.error(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={`modal-overlay ${showLoginModal ? "show" : ""}`}
      onClick={handleCloseModal}
    >
      <section className="modal" onClick={(e) => e.stopPropagation()}>
        <span className="modal__cross" onClick={() => closeModal()}>
          &#10006;
        </span>

        <form className="loginForm" onSubmit={handleLogin}>
          <input
            className="loginForm__input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("login.email_placeholder")}
            required
          />

          <div className="loginForm__input-container">
            <input
              type={inputType}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="loginForm__input loginForm__input--noShadow"
              placeholder={t("login.password_placeholder")}
              required
            />
            <img
              className="loginForm__icon"
              onClick={togglePasswordVisibility}
              src={inputType === "password" ? closeEye : openEye}
              alt="Toggle visibility"
            />
          </div>

          <button
            type="submit"
            className="loginForm__btn"
            disabled={isSubmitting}
          >
            {isSubmitting ? t("toasts.loading") : t("common.continue")}
          </button>

          <p className="loginForm__link" onClick={handlePassRecover}>
            {t("login.forgot_password")}
          </p>
        </form>
      </section>
    </div>
  );
};
