import React, { createContext, useContext, useState } from "react";
import { LoginModal } from "./LoginModal/loginModal";

const ModalContext = createContext();
export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({ isOpen: false, type: null });

  const openModal = (type) => setModal({ isOpen: true, type });
  const closeModal = () => setModal({ isOpen: false, type: null });

  return (
    <ModalContext.Provider value={{ openModal, closeModal, modal }}>
      {children}

      {modal.isOpen && modal.type === "login" && <LoginModal />}
    </ModalContext.Provider>
  );
};
