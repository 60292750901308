import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BlockScroll,
  UnblockScroll,
  scrollToElement,
} from "../../utils/scroll";
import { Playlist } from "./playlist";

import playBtn from "../../assets/img-playback_btns/play.png";
import stopBtn from "../../assets/img-playback_btns/stop.png";
import pauseBtn from "../../assets/img-playback_btns/pause.png";
import forwardBtn from "../../assets/img-playback_btns/forward.png";
import backBtn from "../../assets/img-playback_btns/back.png";
import optionsBtn from "../../assets/img-playback_btns/options.png";
import youtubeImg from "../../assets/img-logo/youTube.png";
import crossBtn from "../../assets/cross.png";
import "./radioStyle.scss";

export const Radio = ({ setTrackName, isPlaying, setIsPlaying }) => {
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const { t } = useTranslation();
  const playerRef = useRef(null);

  const handleVideoSelect = (videoId, index) => {
    setCurrentVideoId(videoId);
    setCurrentIndex(index);
  };

  // передаєм назву треку нагору в хедер
  const updateTrackName = (index) => {
    const currentVideo = videos[index];
    if (currentVideo) {
      setTrackName({
        artistName: currentVideo.snippet.videoOwnerChannelTitle.replace(
          " - Topic",
          ""
        ),
        songName: currentVideo.snippet.title,
      });
    }
  };

  // Функція для переходу до наступного треку
  const handleNextTrack = () => {
    updateTrackName(currentIndex);
    setCurrentIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % videos.length;
      return nextIndex;
    });
  };

  // Функція для переходу до попереднього треку
  const handlePrevTrack = () => {
    updateTrackName(currentIndex);
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + videos.length) % videos.length;
      return newIndex;
    });
  };

  const handlePlay = () => {
    // Якщо плеєр ще не готовий, спробуємо його завантажити
    if (!isPlayerReady) {
      console.log("Player not ready, loading track...");
      createOrUpdatePlayer();
      return;
    }

    if (
      playerRef.current &&
      typeof playerRef.current.playVideo === "function"
    ) {
      updateTrackName(currentIndex);
      playerRef.current.playVideo();
      setIsPlaying(true);
    }
  };

  const handleStop = () => {
    if (
      playerRef.current &&
      typeof playerRef.current.stopVideo === "function"
    ) {
      playerRef.current.stopVideo(); // Зупиняємо поточний трек
      setCurrentTime(0);
      setIsPlaying(false);
    }
  };

  const handlePause = () => {
    if (
      playerRef.current &&
      typeof playerRef.current.pauseVideo === "function"
    ) {
      playerRef.current.pauseVideo();
      setIsPlaying(false);
    }
  };

  const createOrUpdatePlayer = () => {
    if (
      playerRef.current &&
      typeof playerRef.current.loadVideoById === "function"
    ) {
      playerRef.current.loadVideoById(currentVideoId);
    } else {
      playerRef.current = new window.YT.Player("player", {
        videoId: currentVideoId,
        playerVars: {
          controls: 1,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
        events: {
          onReady: () => {
            setIsPlayerReady(true);
          },
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.BUFFERING) {
              console.log("Buffering...");
            } else if (event.data === window.YT.PlayerState.CUED) {
              console.log("Ready!");
            } else if (event.data === window.YT.PlayerState.PLAYING) {
              playerRef.current.setVolume(100);
              setIsPlaying(true);
              if (playerRef.current.getDuration() > 0) {
                setDuration(playerRef.current.getDuration());
              }
            } else if (event.data === window.YT.PlayerState.ENDED) {
              handleNextTrack();
            } else if (event.data === window.YT.PlayerState.PAUSED) {
              playerRef.current.setVolume(0);
              setIsPlaying(false);
            }
          },
        },
      });
    }
  };

  useEffect(() => {
    // Викликаємо функцію для оновлення назви треку
    updateTrackName(currentIndex);
    const videoId = videos[currentIndex]?.snippet?.resourceId?.videoId;
    setCurrentVideoId(videoId || currentVideoId);
  }, [currentIndex, videos]);

  useEffect(() => {
    // боремся з глюками
    // Встановлюємо гучність на 0 перед перемиканням треку
    if (
      playerRef.current &&
      typeof playerRef.current.setVolume === "function"
    ) {
      playerRef.current.setVolume(0);
    }

    // Прокрутка до елемента
    if (currentIndex) {
      scrollToElement(currentIndex);
    }

    // Логіка для завантаження або оновлення плеєра
    if (currentVideoId) {
      if (window.YT) {
        createOrUpdatePlayer();
      } else {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
        window.onYouTubeIframeAPIReady = () => {
          createOrUpdatePlayer();
        };
      }
    }

    // Цей хук оновлює стан позунка прокрутки аудіо
    const interval = setInterval(() => {
      if (
        playerRef.current &&
        isPlaying &&
        typeof playerRef.current.getCurrentTime === "function"
      ) {
        setCurrentTime(playerRef.current.getCurrentTime());
      }
    }, 500);
    return () => clearInterval(interval);
  }, [currentVideoId]);

  const handleSeek = (event) => {
    const newTime = event.target.value;
    setCurrentTime(newTime);
    if (playerRef.current && typeof playerRef.current.seekTo === "function") {
      playerRef.current.seekTo(newTime);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleExpandOpen = () => {
    BlockScroll();
    setIsExpanded(true);
  };

  const handleExpandClose = () => {
    UnblockScroll();
    setIsExpanded(false);
  };

  return (
    <div
      className={`radio ${isExpanded && "radio--expanded"}`}
      onMouseLeave={handleExpandClose}
    >
      <div className={`radio__compact ${isExpanded && "hidden"}`}>
        {isPlaying ? (
          <img
            className="player__img"
            src={stopBtn}
            onClick={handleStop}
            alt="Stop"
          />
        ) : (
          <img
            className="player__img"
            src={playBtn}
            onClick={handlePlay}
            alt="Play"
          />
        )}

        <img
          className="player__img"
          src={forwardBtn}
          onClick={handleNextTrack}
          alt="Next"
        />
        <img
          className="player__img"
          onClick={handleExpandOpen}
          src={optionsBtn}
          alt="Next"
        />
      </div>

      <div className={`radio__expanded ${isExpanded || "hidden"}`}>
        <img
          className="radio__cross"
          onClick={(event) => handleExpandClose(event)}
          src={crossBtn}
          alt="crossBtn"
        />

        <div className="radio__title">
          <img className="radio__title-img" src={youtubeImg} alt="youtubeImg" />
          <h1 className="radio__title-string">YouTube music</h1>
        </div>

        <Playlist
          selectVideo={handleVideoSelect}
          setVideos={setVideos}
          currentVideoId={currentVideoId}
          setTrackName={setTrackName}
          isPlaying={isPlaying}
          t={t}
        />

        {videos.length > 0 && (
          <main className="player">
            <div
              id="player"
              style={{ width: "0", height: "0", visibility: "hidden" }}
            ></div>

            {duration > 0 ? (
              <div className="player__trackBar">
                <input
                  className="player__input"
                  type="range"
                  min="0"
                  max={duration}
                  value={currentTime}
                  onChange={handleSeek}
                  style={{
                    backgroundImage: `linear-gradient(to right, #009fe3 0%, #009fe3 ${
                      (currentTime / duration) * 100
                    }%, transparent ${
                      (currentTime / duration) * 100
                    }%, transparent 100%)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                  }}
                />
                <p className="player__time">
                  {formatTime(currentTime)} / {formatTime(duration)}
                </p>
              </div>
            ) : (
              <div className="player__trackBar">
                <input
                  className="player__input"
                  type="range"
                  value={currentTime}
                  readOnly
                />
                <p className="player__time">{t("toasts.loading")}</p>
              </div>
            )}

            <div className="player__navAudio">
              <img
                className="player__img player__img--large"
                src={backBtn}
                onClick={handlePrevTrack}
                alt="Prev"
              />
              <img
                className="player__img player__img--large"
                src={stopBtn}
                onClick={handleStop}
                alt="Stop"
              />
              <img
                className="player__img player__img--large"
                src={playBtn}
                onClick={handlePlay}
                alt="Play"
              />
              <img
                className="player__img player__img--large"
                src={pauseBtn}
                onClick={handlePause}
                alt="Pause"
              />
              <img
                className="player__img player__img--large"
                src={forwardBtn}
                onClick={handleNextTrack}
                alt="Next"
              />
            </div>
          </main>
        )}
      </div>
    </div>
  );
};
